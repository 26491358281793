import { IHome } from "@/interfaces/home";
import { IFinalProductProps } from "@/interfaces/products";
import { currencyFormat } from "@/lib/CurrencyFormat";
import { getFirstProductImage } from "@/lib/GetFirstProductImage";
import { TruncateText } from "@/lib/TruncateText";
import { PRODUCT_CARDS } from "@/lib/CardsType";
import React, { useEffect, useState } from "react";
import { IStaticLocale } from "@/interfaces/staticlocale";

export const Product = ({ product , home, cardType, locale }: ProductProps) => {

    const { name, internalName, shortDescription, images, currentTariff } = product
    const [isBrowser, setIsBrowser] = useState(false)
    const detailLocale = locale.product_detail;
    
    useEffect( () => {
        setIsBrowser(true)
    }, [])

    return (
        isBrowser ? (<a href={product.currentPath}>
            <div className={`${cardType === PRODUCT_CARDS.WITH_ACTION ? PRODUCT_CARDS.WITH_ACTION : PRODUCT_CARDS.DEFAULT}`}>

                <div className="image">
                    <img src={getFirstProductImage({ images, styleName: 'thumbnail' })} alt={internalName} />
                </div>

                <div className="content-information">
                    <div className="top-content">
                        <h1 className="title">{TruncateText(name, 38)}</h1>
                        <p className="description">{TruncateText(shortDescription)}</p>
                    </div>
                    
                    <div className="bottom-content">
                        <div className="content-information-in-bottom">
                            {currentTariff?.oldPriceCents && (
                                <p className="old-price">
                                    {detailLocale.from} <span>R$ {currencyFormat(currentTariff.oldPriceCents)}</span> {detailLocale.to}
                                </p>
                            )}
                            <p className="price">R$ {currencyFormat(currentTariff?.priceCents!)}</p>
                            <p className="type">{ home.productList.homeProductListUnderPrice }</p>
                            { cardType === PRODUCT_CARDS.WITH_ACTION ? 
                                <a href={product.currentPath}>
                                    <button className="action">{ home.productList.homeProductListCta }</button>
                                </a> : <></>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </a>
        ): <></>
    )
}

interface ProductProps{
    product: IFinalProductProps;
    home: IHome;
    cardType?: string;
    lang: string;
    CtaColor?: string;
    locale: IStaticLocale;
}
