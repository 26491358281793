import React from 'react';
import { Product } from '@/components/Products/Product';
import { IAppDataContent } from '@/interfaces/appdata';
import {
  ICategoryWithProducts,
  IFinalProductProps,
} from '@/interfaces/products';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import './categories.style.scss';

const Home = ({ pageContext }: CategoriesProps) => {
  const { templateData, locale } = pageContext.appData;
  const { notListedProducts, homeDisplay } = templateData.home;

  const defaultLayoutData = {
    pageContext,
    templateData,
    title: pageContext.categoryName,
    display: homeDisplay,
  };

  const produtsToList = pageContext.categoryProducts.filter(
    (product) => !notListedProducts?.productsId.includes(product.id!)
  );

  return (
    <DefaultLayout {...defaultLayoutData}>
      <section className="default-screen-size products_content">
        <div className="products_content">
          <div className="products_intro_section">
            <h1 className={`typ-6`}>{pageContext.categoryName}</h1>
            <p className={`typ-3`}>
              {templateData.home.productList.homeProductListSubTitle}
            </p>
          </div>

          {produtsToList.length > 0 &&
            produtsToList.map((product) => (
              <div className="card-display products_list">
                <Product
                  key={product.id}
                  {...{
                    lang: pageContext.lang,
                    product,
                    home: templateData.home,
                    productCta: templateData.products.productCta!,
                    locale,
                  }}
                />
              </div>
            ))}
        </div>
      </section>
    </DefaultLayout>
  );
};

interface CategoriesProps {
  pageContext: {
    appData: IAppDataContent;
    lang: string;
    categoryName: string;
    categoryProducts: IFinalProductProps[];
    categoriesWithProducts: ICategoryWithProducts[];
    currentPath: string;
    pathLang: string;
    widgetTarget: string;
  };
}

export default Home;
